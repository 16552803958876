import React from "react";
import { Drawer, Box, List, ListItem, ListItemText, Typography } from "@mui/material";

export default function SideBar({ open, onClose, rubros, setRubroSeleccionado, rubroSeleccionado }) {
    const handleClick = () => {
        onClose();
    };

    return (
        <Drawer
            anchor="left"
            open={open}
            onClose={onClose}
            PaperProps={{
                sx: {
                    width: {
                        xs: "75%",
                        sm: "350px",
                    },
                    border: "2px solid",
                    borderImage: "linear-gradient(180deg, #EF9900, #FD00FF) 1", // Borde degradado
                    overflowX: "hidden",
                    "&::-webkit-scrollbar": {
                        width: "5px",
                    },
                    "&::-webkit-scrollbar-track": {
                        background: "#EF9900",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: "#FD00FF",
                    },
                    "&::before": {
                        content: "''",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: 100,
                        maxWidth: '75px',
                        height: "100%",
                        background: "linear-gradient(#EF9900, #FD00FF)",
                        zIndex: -1,
                    },
                },
            }}
        >
            <Box container sx={{ height: "100%", display: "flex", flexDirection: "row" }}>
                <Box sx={{ display: "flex", flexDirection: "row", height: "100%" }}>
                    {/* Columna de logos con degradado */}
                    <List
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            width: 100,
                            maxWidth: '75px',
                            height: "100%",
                            padding: 2,
                            paddingTop: 3,
                        }}
                    >
                        {rubros?.map((rubro) => (
                            <ListItem
                                button
                                key={rubro.id}
                                onClick={() => {
                                    setRubroSeleccionado(rubro);
                                    handleClick();
                                }}
                                sx={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: 40, // Asegura un ancho uniforme
                                    height: 40, // Asegura una altura uniforme
                                    border: "2px solid #FFF",
                                    borderRadius: "50%", // Hace que sea un círculo perfecto
                                    marginBottom: 2,
                                    color: rubroSeleccionado?.id === rubro.id ? "#FFF" : "#000",
                                    backgroundColor: rubroSeleccionado?.id === rubro.id ? "#9C27B0" : "transparent",
                                    "&:hover": {
                                        backgroundColor: rubroSeleccionado?.id === rubro.id ? "#7B1FA2" : "#F5F5F5",
                                    },
                                }}
                            >
                                L
                            </ListItem>
                        ))}
                    </List>

                    {/* Columna de nombres */}
                    <List sx={{ width: "100%", height: "100%", padding: 2, paddingTop: 3 }}>
                        {rubros?.map((rubro) => (
                            <ListItem
                                button
                                key={rubro.id}
                                onClick={() => {
                                    setRubroSeleccionado(rubro);
                                    handleClick();
                                }}
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    height: 40, // Uniformidad en la altura
                                    paddingLeft: 2,
                                    paddingRight: 2,
                                    borderRadius: 5,
                                    marginBottom: 2, // Espaciado entre ítems
                                    color: rubroSeleccionado?.id === rubro.id ? "#000" : "#FD00FF",
                                    backgroundColor: rubroSeleccionado?.id === rubro.id ? "#FFB74D" : "#FFE0B2",
                                    "&:hover": {
                                        backgroundColor: rubroSeleccionado?.id === rubro.id ? "#FFA726" : "#FFCC80",
                                    },
                                }}
                            >
                                <ListItemText primary={rubro.nombre} />
                            </ListItem>
                        ))}
                        <div style={{ height: '2px' }}></div>
                    </List>
                </Box>
            </Box>
        </Drawer>
    );
}
