import { AppBar, Toolbar, IconButton, Typography, useMediaQuery } from '@mui/material';
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import SideBar from "./SideBar";

export default function Header(props) {
  const [sideBarOpen, setSideBarOpen] = useState(false);

  const { rubros, setRubroSeleccionado, rubroSeleccionado } = props;

  const toggleSideBar = () => {
    setSideBarOpen(!sideBarOpen);
  };

  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          background: "linear-gradient(to right, #FD00FF, #EF9900)", // Gradiente de rosa a naranja
        }}
      >
        <Toolbar style={{ justifyContent: "space-between" }}>
          <div style={{ display: "flex", alignItems: "center", width: '100%', justifyContent: isMobile ? 'center' : 'flex-start' }}>
            <IconButton
              onClick={toggleSideBar}
              edge="start"
              color="inherit"
              aria-label="menu"
              style={{ marginRight: isMobile ? 'auto' : '0' }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{
              flexGrow: 1,
              textAlign: isMobile ? 'center' : 'left'
            }}>              {rubroSeleccionado ? rubroSeleccionado.nombre : "Marketplace"}
            </Typography>
          </div>
        </Toolbar>
      </AppBar>
      <SideBar open={sideBarOpen} onClose={toggleSideBar} rubros={rubros} rubroSeleccionado={rubroSeleccionado} setRubroSeleccionado={setRubroSeleccionado} />
    </>
  );
};